import React from 'react'

import Testimonials from '../testimonials'

const HomeContent = ({
  hideCta = false,
  phone = '866-740-2901',
  noScrollCTA = false,
  hidePlans = false,
  hideBenefitsSection = false,
}) => {
  return (
    <>
      {!hideBenefitsSection && (
        <div className="benefits-section alt">
          <div className="container">
            <div className="two-blocks">
              <div className="image-block">
                <div className="decor-img">
                  <div
                    className="img-frame"
                    style={{ backgroundImage: 'url(/images/img-001.jpg)' }}
                  />
                </div>
              </div>
              <div className="text-block">
                <strong className="h2">Why should I use Medicare Companion?</strong>
                <p>
                  Medicare parts A & B are filed with and regulated by your state’s
                  Department of Insurance. Whether you buy from Medicare Companion, your
                  local agent, or directly from the health insurance company, you’ll pay
                  the same monthly premium for the same plan. This means that you can
                  enjoy the advantages and convenience of shopping and purchasing your
                  additional Medicare coverage through Medicare Companion and rest assured
                  that you’re getting the best available price.
                </p>
              </div>
            </div>
            <div className="two-blocks reverse">
              <div className="image-block">
                <div className="decor-img">
                  <div
                    className="img-frame"
                    style={{ backgroundImage: 'url(/images/img-002.jpg)' }}
                  />
                </div>
              </div>
              <div className="text-block">
                <strong className="h2">
                  Open Enrollment Period for additional Medicare insurance coverage
                </strong>
                <p>
                  Medicare Open Enrollment Period begins the first day of the month in
                  which you are at least 65 and covered under Medicare Part B. You have
                  six months to buy any additional Medicare coverage with no limitations
                  to plan options. If you are under 65, check with your state’s Social
                  Security Administration to see if it offers additional open enrollment
                  periods.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Testimonials bgNavy hideHeader />
      {!hidePlans && (
        <div className="bg-light plans-section alt">
          <div className="container">
            <div className="section-heading text-center">
              <h2>Understanding Premiums with Lower Out-of-Pocket Costs</h2>
            </div>
            <ul className="plans-list">
              <li>
                <div className="plan-item">
                  <div className="top-block bg-pink">
                    <h3>
                      Plans <strong>A&amp;B</strong>
                    </h3>
                  </div>
                  <div className="content">
                    <p>
                      Medicare Plans A & B cover basic co-insurance and copayment costs
                      for hospitalization, medical expenses, and hospice care. Medicare
                      Plan B provides additional coverage for Part A deductibles.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="plan-item">
                  <div className="top-block bg-dark-purple">
                    <h3>
                      Plan <strong>C</strong>
                    </h3>
                  </div>
                  <div className="content">
                    <p>
                      Medicare Supplement Plan C covers the basic Medicare benefits plus
                      skilled nursing care, certain Medicare Part A and Part B deductibles
                      and emergency care abroad.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="plan-item">
                  <div className="top-block bg-navy">
                    <h3>
                      Plan <strong>F</strong>
                    </h3>
                  </div>
                  <div className="content">
                    <p>
                      Covers the basic Medicare benefits, plus coverage for Medicare Part
                      A and B deductibles, skilled nursing care, emergency care abroad and
                      100% of Part B excess charges—this is the difference between what a
                      provider charges and the amount Medicare will pay up to Medicare’s
                      limiting amount. A high-deductible option is available that can
                      lower your premiums.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="plan-item">
                  <div className="top-block bg-blue">
                    <h3>
                      Plan <strong>G</strong>
                    </h3>
                  </div>
                  <div className="content">
                    <p>
                      Medicare Supplement Plan G covers basic Medicare benefits plus
                      coverage for additional costs like Part A deductibles, skilled
                      nursing care, Part B excess charges (charges above what Medicare
                      pays) and emergency care abroad.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            {!hideCta && (
              <div className="bottom-block text-center">
                <strong className="title">
                  Still have questions about your Medicare options? Speak to a licensed
                  insurance agent!
                </strong>
                <a href={`tel:${phone}`} className="btn with-icon">
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="base-section faq-section alt">
        <div className="container container-md">
          <div className="section-heading text-center">
            <h2>Frequently Asked Questions</h2>
          </div>
          <ul className="faq-list">
            <li>
              <a className="opener">Why should I use MedicareCompanion.org?</a>
              <div className="slide">
                <p>
                  MedicareCompanion.org helps you learn about your Medicare coverage
                  options and connect you with a licensed insurance agent. A licensed
                  agent can answer your questions and help you find the right Medicare
                  option to fit your needs and budget.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">Do I have to pay for your help?</a>
              <div className="slide">
                <p>
                  No, we provide our services at no charge to you. We are happy to answer
                  your Medicare-related questions, help and you review your plan options.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">What if I don't know what plan I need?</a>
              <div className="slide">
                <p>
                  US-based licensed insurance agent can help you assess what's important
                  to you and find an affordable plan that gives you some peace of mind
                  knowing you are covered.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">What if I already have a Medicare coverage?</a>
              <div className="slide">
                <p>
                  If you already have Medicare coverage, but want to explore your options,
                  you are in the right place. Simply call{' '}
                  <a href={`tel:${phone}`}>1-{phone}</a> or fill out the form at the top
                  or bottom of this page.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">How do I get started?</a>
              <div className="slide">
                <p>
                  You can get started by filling out the form on the top or bottom of this
                  page or by calling <a href={`tel:${phone}`}>1-{phone}</a>. The licensed
                  insurance agent will help you through the entire process, helping find a
                  plan that works for you.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="cta-section bg-light base-section alt">
        <div className="container container-sm">
          <div className="section-heading text-center">
            <h2>Find Affordable Medicare Plan Coverage</h2>
            <p>
              First, just answer a few questions for us so we can connect you with a
              licensed insurance agent.
            </p>
          </div>
          <div className="text-center">
            {noScrollCTA ? (
              <a href={`tel:${phone}`} className="btn with-icon">
                <span className="icon-phone" /> <span className="text">{phone}</span>
              </a>
            ) : (
              <button
                href="#form-box"
                className="btn"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                Find Medicare Plans
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeContent
