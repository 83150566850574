import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { connect } from 'react-redux'

import newBgImg from '../../../images/surfers-wide.jpg'

const Intro = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  classNames, // TODO: Deprecated
  className = '',
  hideBg = false,
  newApply,
  showCtaButton,
  phone,
  homeOrigin,
}) => {
  const applyBgImg = newApply ? newBgImg : bgImage
  const tel = phone.replace('-', '').replace('-', '')
  const newDesign = homeOrigin === 'c1-new' || homeOrigin === 'd1-new'

  // TODO: try using gatsby-background-image

  return (
    <div
      className={clsx(
        'intro-section bg-intro alt',
        hideBg && 'intro-section--hide-bg',
        classNames,
        className,
      )}
      style={newDesign ? {} : hideBg ? {} : { backgroundImage: `url('${applyBgImg}')` }}
    >
      {hideBg && <div className="intro-section__border container" />}

      <div className="intro-section__content container">
        <div className={colType}>
          {showTitle && (
            <div className="title-holder">
              <h1 className="intro-section__title">{title}</h1>
              {showSubTitle && <p>{subTitle}</p>}
              {showCtaButton && (
                <a href={`tel:${tel}`} className="btn with-icon">
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

Intro.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
  showCtaButton: PropTypes.bool,
  phone: PropTypes.string,
  homeOrigin: PropTypes.string,
}

Intro.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/bg-img-01.jpg',
  colType: 'left-col',
  title: 'Explore your Medicare options today.',
  subTitle:
    'With Medicare Companion, connect with a licensed insurance agent today and learn more about your Medicare options.',
  classNames: '',
  showCtaButton: false,
  phone: '',
  homeOrigin: null,
}

export default connect(
  (state) => ({ newApply: state.captive.newApply, homeOrigin: state.captive.homeOrigin }),
  {},
)(Intro)
